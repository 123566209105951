import { styles } from "./GoogleReviews.style";
import { ElfsightWidget } from "next-elfsight-widget";

export interface IGoogleReviews {
  src: string;
}

export const GoogleReviews = ({ src }: IGoogleReviews) => {
  return (
    <div css={styles.divRoot()}>
      <ElfsightWidget lazy="in-viewport" widgetId={src} />
    </div>
  );
};
