import React from "react";
import { GoogleReviews } from "@carfax/web-ui/components/GoogleReviews";
import { googleReviewsSources } from "./GoogleReviewsSources";

export interface IGoogleReviews {
  module: {
    opinionSource: string;
  };
}

const GoogleReviewsCommonModule: React.FC<IGoogleReviews> = ({ module }) => {
  return (
    <div>
      <GoogleReviews src={googleReviewsSources[module.opinionSource]} />
    </div>
  );
};

export default GoogleReviewsCommonModule;
