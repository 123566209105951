import { css } from "@emotion/react";
import { mediaQuery, spacing } from "../../core";

export const styles = {
  divRoot: () =>
    css({
      height: "374px",
      position: "relative",
      display: "block",
      margin: `0 ${spacing.baseUnitMobile2}`,
      width: "calc(100% - 32px)",
      iframe: {
        height: "100%",
        width: "100%",
        position: "absolute",
        top: "0",
        left: "0",
      },
      [mediaQuery.tabletOrLarger]: {
        height: "358.67px",
      },
    }),
};
